import React from "react"

import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import MobileAppFeatures from "./MobileAppFeatures/MobileAppFeatures"
import MobileAppFooter from "./MobileAppFooter/MobileAppFooter"
import MobileAppHeader from "./MobileAppHeader/MobileAppHeader"
import SEO from "~/components/Seo/Seo"
import { Video } from "~/models/Video"

interface PropTypes extends TemplateDefaultPropTypes {
  data: {
    video: Video
  }
}

function MobileApp(props: PropTypes) {
  const intl = useIntl()
  const seoMeta = {
    title: intl.formatMessage({ id: "mobile-app/text:seo-title" }),
    description: intl.formatMessage({ id: "mobile-app/text:seo-description" }),
  }

  return (
    <>
      <SEO {...seoMeta} location={props.location} />

      <MobileAppHeader video={props.data.video} />
      <MobileAppFeatures />
      <MobileAppFooter />
    </>
  )
}

export const query = graphql`
  query MobileApp($language: String) {
    video(
      chefclub_video_id: { eq: "app_promotion_on_website" }
      language: { eq: $language }
    ) {
      ...Video
    }
  }
`

export default withTemplate(MobileApp)
