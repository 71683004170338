import React from "react"

import { FormattedMessage } from "react-intl"

import AppDownloadButtons from "~/components/AppDownloadButtons/AppDownloadButtons"
import { Body, Col, Container, Heading, Row } from "~/components/ui"

import css from "./MobileAppFooter.module.scss"

function MobileAppFooter() {
  return (
    <section className={css.downloadSection}>
      <Container>
        <Row justify="center">
          <Col width={[12, 8]}>
            <Heading
              className={css.title}
              Tag="h1"
              variant="extraLarge"
              color="white"
              align="center"
            >
              <FormattedMessage id="mobile-app/section:become-a-star-in-the-app" />
            </Heading>

            <AppDownloadButtons />

            <Body variant="body3" semiBold align="center">
              <FormattedMessage id="mobile-app/text:download-the-app" />
            </Body>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default MobileAppFooter
