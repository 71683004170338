import React from "react"

import { FormattedMessage } from "react-intl"

import chefsHatIcon from "../assets/chefsHat.svg"
import chocolateSpreadIcon from "../assets/chocolateSpread.svg"
import heartIcon from "../assets/heart.svg"
import starIcon from "../assets/star.svg"
import { Body, Col, Container, Heading, Row } from "~/components/ui"

import css from "./MobileAppFeatures.module.scss"

const features = [
  {
    icon: chefsHatIcon,
    title: "mobile-app/text:feature-1-title",
    text: "mobile-app/text:feature-1-text",
  },
  {
    icon: starIcon,
    title: "mobile-app/text:feature-2-title",
    text: "mobile-app/text:feature-2-text",
  },
  {
    icon: chocolateSpreadIcon,
    title: "mobile-app/text:feature-3-title",
    text: "mobile-app/text:feature-3-text",
  },
  {
    icon: heartIcon,
    title: "mobile-app/text:feature-4-title",
    text: "mobile-app/text:feature-4-text",
  },
]

function MobileAppFeatures() {
  return (
    <section className={css.root}>
      <Container>
        <Row justify="center">
          <Col width={[12, 10]}>
            <Row>
              {features.map(({ icon, title, text }, i) => (
                <Col className={css.item} key={i} width={[12, 6]}>
                  <img loading="lazy" src={icon} />
                  <Heading variant="large" align="center">
                    <FormattedMessage id={title} />
                  </Heading>
                  <Body variant="body3" align="center">
                    <FormattedMessage id={text} />
                  </Body>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default MobileAppFeatures
