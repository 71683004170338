import React, { CSSProperties } from "react"

import { graphql, useStaticQuery } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"
import { FormattedMessage } from "react-intl"

import arrowDownIcon from "../assets/arrowDown.svg"
import AppDownloadButtons from "~/components/AppDownloadButtons/AppDownloadButtons"
import { Body, Col, Container, Heading, Row } from "~/components/ui"
import VideoPlayer from "~/components/VideoPlayer/VideoPlayer"
import { Video } from "~/models/Video"

import css from "./MobileAppHeader.module.scss"

interface PropTypes {
  video: Video
}

interface DataTypes {
  iPhone: FileNode
}

function MobileAppHeader({ video }: PropTypes) {
  const { iPhone } = useStaticQuery<DataTypes>(graphql`
    query {
      iPhone: file(relativePath: { eq: "MobileApp/Iphone.png" }) {
        childImageSharp {
          gatsbyImageData(width: 204, quality: 100, placeholder: BLURRED)
        }
      }
    }
  `)

  const iPhoneSrc = getSrc(iPhone)!

  return (
    <section className={css.headerSection}>
      <Container>
        <Row>
          <Col width={[12, 4]} offset={[0, 1]}>
            <div className={css.iPhoneWrap}>
              <img alt="iPhone" src={iPhoneSrc} />
              <div
                className={css.videoWrap}
                style={
                  {
                    "--base-border-radius": 0,
                  } as CSSProperties
                }
              >
                {video && (
                  <VideoPlayer
                    src={video.hostedVideoUrl}
                    poster={video.videoPreviewThumbnail}
                    verticalColor="black"
                    format="vertical"
                    disableControls
                    autoPlay
                  />
                )}
              </div>
            </div>
          </Col>
          <Col width={[12, 5]}>
            <div className={css.content}>
              <Heading
                Tag="h1"
                className={css.title}
                variant="extraLarge"
                color="white"
                align={["center", "left"]}
              >
                <FormattedMessage id="mobile-app/section:page-title" />
              </Heading>

              <div className={css.storeBadges}>
                <AppDownloadButtons />
              </div>

              <Body variant="body3" semiBold align={["center", "left"]}>
                <FormattedMessage id="mobile-app/text:download-the-app" />
              </Body>
            </div>
          </Col>
        </Row>
      </Container>

      <span className={css.downButton}>
        <img loading="lazy" src={arrowDownIcon} />
      </span>
    </section>
  )
}

export default MobileAppHeader
